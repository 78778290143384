import React, { useRef } from 'react';
import thrive from './thrive.png';
import bitgreen from './bitgreen.png';
import './App.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Login = () => {
  const emailRef = useRef();

  const [state, setState] = useState({
    loading: false,
    show_message: false,
});

  const handleSubmit = async (event) => {  
    setState(prevState => ({ ...prevState, loading: true }));  
    //console.log(process.env.REACT_APP_STRIPE_KEY);
    
    event.preventDefault();
    const email = emailRef.current.value;

    try {
      fetch("https://solar.bitgreen.org/api/auth/", {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
          "email": email
        })
      }).then(() => {
        toast('Check email for signin link!', {
          duration: 1000,
          position: 'top-center',
          icon: '✔️',
        });
      });
    } catch (error) {
      //console.log(error);
    }
  }

  return (
    <Container>
      <Toaster />
      <br /> <br /> <br /> <br /> <br /> <br />
      <Row>
        <Col xs={6} md={6}>
          <br /> <br />
          <h2>Thrive : Home Owner Portal</h2>
          <br /> <br />
          <h4>Powered by : </h4>
          <Image height={100} width={200}  src={thrive} />
          <Image height={55} width={200}  src={bitgreen} />
        </Col>
        <Col xs={4} md={4}>
          <br /> <br /> <br />
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" ref={emailRef} />
              <br />
              <Form.Text className="text-muted">
              Welcome to the owner portal for your home solar system. Enter your email to receive a link to login.
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Request Account Link
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
