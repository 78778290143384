import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import thrive from './thrive.png';
import bitgreen from './bitgreen.png';
import {
    Elements,
    useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import toast, { Toaster } from 'react-hot-toast';

const stripePromise = loadStripe("pk_test_51OhxymI8LaaJlkuzX2XAXoS503jPf3LRhoXKdIiYvtb0KYHECylJl8R8RSJuwalQiztpRa2VlA0XiqstcTlHx5lu00HD1tKdEe");

const App = () => {
    //const history = useHistory();
    let usestripeReact = useStripe();

    const [state, setState] = useState({
        name: "User",
        account_number: "",
        routing_number: "",
        payment: "",
        showModal: false,
        loading: false,
        user_token: "",
        email: "",
        stripe_setup_completed: false
    });

    useEffect(() => {
        async function fetchData() {

            let search = window.location.search;
            let params = new URLSearchParams(search);
            let user_token = params.get('user_token');
            //console.log({ user_token });

            if (!user_token) {
                window.location.replace('/thrive/login');
                return;
            }
    
            try {
                let response = await fetch("https://solar.bitgreen.org/api/" + '/fetchUser', {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user_token}`,
                    },
                });
                let data = await response.json();
                //console.log(data);
                setState(prevState => ({
                    ...prevState,
                    user_token: user_token,
                    name: data.name,
                    account_number: data.account_number,
                    routing_number: data.routing_number,
                    payment: data.payment_amount,
                    email: data.email,
                    stripe_setup_completed: data.stripe_setup_completed
                }));
                toast('Login Success', {
                    duration: 500,
                    position: 'top-center',
                    icon: '✔️',
                })
            } catch (err) {
                window.location.replace('/thrive/login');
            }
        }

        fetchData();
    }, []);

    const handleShow = () => {
        //console.log("here");
        setState(prevState => ({ ...prevState, showModal: true }));
    }

    const handleClose = () => {
        setState(prevState => ({ ...prevState, showModal: false }));
    }

    const handleContinue = async () => {
        setState(prevState => ({ ...prevState, showModal: false, loading: true }));


        try {
            let response = await fetch("https://solar.bitgreen.org/api/" + '/startStripePayment', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${state.user_token}`,
                },
            });
            let data = await response.json();

            usestripeReact.collectBankAccountForPayment({
                clientSecret: data.message,
                params: {
                    payment_method_type: 'us_bank_account',
                    payment_method_data: {
                        billing_details: {
                            name: state.name,
                            email: state.email,
                        },
                    },
                },
                expand: ['payment_method'],
            })
                .then(({ paymentIntent, error }) => {
                    if (error) {
                        console.error(error.message);
                        toast('Payment Failed. Try again later!', {
                            duration: 1000,
                            position: 'top-center',
                            icon: '✔️',
                        });
                    } else if (paymentIntent.status === 'requires_payment_method') {
                        toast('Confirmation Failed. Try again later!', {
                            duration: 1000,
                            position: 'top-center',
                            icon: '✔️',
                        });
                    } else if (paymentIntent.status === 'requires_confirmation') {
                        toast('Bank Account Confirmed! Processing payment..', {
                            duration: 1000,
                            position: 'top-center',
                            icon: '✔️',
                        });
                        usestripeReact.confirmUsBankAccountPayment(data.message)
                            .then(({ paymentIntent, error }) => {
                                //console.log(paymentIntent);
                                if (error) {
                                    console.error(error.message);
                                    // The payment failed for some reason.
                                } else if (paymentIntent.status === "requires_payment_method") {
                                    toast('Payment Failed. Try again later!', {
                                        duration: 1000,
                                        position: 'top-center',
                                        icon: '✔️',
                                    });
                                } else if (paymentIntent.status === "processing") {
                                    toast('Payment Success!', {
                                        duration: 1000,
                                        position: 'top-center',
                                        icon: '✔️',
                                    });
                                } else if (paymentIntent.next_action?.type === "verify_with_microdeposits") {
                                    toast('Requires Confirmation. Check email for next steps', {
                                        duration: 1000,
                                        position: 'top-center',
                                        icon: '✔️',
                                    });
                                }
                            });
                    }
                });

            setState(prevState => ({ ...prevState, showModal: false, loading: false, stripe_setup_completed: true }));

        } catch (err) {
            //console.log(err);
            alert("Payment setup failed!");
        }
    }

    return (
        <Container>
            <Toaster />
            <br /><br /><br />

            <Row>
                <Col xs={6} md={6}>
                    <br /> <br />
                    <h2>Bitgreen Payment Portal</h2>
                    <h4>Hello, {state.name}</h4>
                </Col>
            </Row>

            <br /><br /><br />
            <Row>
                <Col xs={6} md={6}>
                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>Setup Autopay</Card.Title>
                            <Card.Text>
                                Confirm your bank account to setup autopay!
                            </Card.Text>
                            <Card.Text>
                                Status : {state.stripe_setup_completed ? "Completed ✅" : "Pending"} <br />
                                {state.loading && (
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                )}

                            </Card.Text>
                            <Button variant="primary" onClick={handleShow} disabled={state.stripe_setup_completed}>Proceed</Button>
                        </Card.Body>
                    </Card>
                    <Modal show={state.showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Setup Autopay</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Proceed to setup autopay with the following details:</Modal.Body>
                        <Modal.Body>Name: <b>{state.name}</b> <br />
                            Account Number: <b>{state.account_number}</b> <br />
                            Routing Number: <b>{state.routing_number}</b></Modal.Body>
                        <Modal.Body>Monthly Payment: <b>{(state.payment) / 100} USD</b></Modal.Body>
                        <Modal.Body>Note : By clicking Continue, you are authorizing Bitgreen to auto debit the monthly payment from your bank account. You will be redirected to Stripe Payments to confirm your bank account details.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleContinue}>
                                Continue
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </Col>
                <Col xs={6} md={6}>
                    <Card style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Title>Help</Card.Title>
                            <Card.Text>
                                For all queries and troubleshooting
                            </Card.Text>
                            <Button variant="primary" href="https://bitgreen.org/contact">Contact Support</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            <br /><br /><br />
            <p><a href="/thrive/login">Logout</a></p>

            <Row className='footer'>
                <Col xs={6} md={6}>
                    <h4>Powered by : </h4>
                    <Image height={100} width={200} src={thrive} />
                    <Image height={55} width={200} src={bitgreen} />
                </Col>
            </Row>
        </Container>
    );
}

const Wrapper = (props) => (
    <Elements stripe={stripePromise}>
        <App />
    </Elements>
);

export default Wrapper;
